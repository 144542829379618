// import Slider from './Components/Slider';
import './App.css';
import Header from './Components/Header1';
import Footer from './Components/Footer';
import Banner from './Components/Banner1';
import Message from './Components/Message';
import Welcome from './Components/Welcome';
import Gallery from './Components/Gallery';
import Schooldes from './Components/Schooldes';
import Contactus from './Components/Contactus';

// Pages
import Contact from './Pages/Contact'
import Home from './Pages/Home';
import Curriculum from './Components/Curriculum';
import HolisticLearning from './Components/HolisticLearning';
import AdmissionProcess from './Components/AdmissionProcess';
import PrePrimary from './Components/PrePrimary';
import Primary from './Components/Primary';
import Secondary from './Components/Secondary';
import Information from './Components/Information';
import Facilities from './Components/Facilities';
import Achievements from './Components/Achievements';
import Building from './Components/Building';
import Playground from './Components/Playground';
import Classroom from './Components/Classrooms';
import Progress_Promation from './Components/Progress_Promation';
import contact from './Components/Contect1';
import Login from './Components/Login';
import Base from './Pages/Base'
import {Routes, Route} from 'react-router-dom'




import { BrowserRouter as Router } from 'react-router-dom';
function App() {
  return (
      <div className="App">
    <Routes>
    <Route exact path='/' element ={<Home/>} />
    <Route path='home' element ={<Home/>} />
    <Route path='/Contact' element ={<Contact/>} />
    <Route path='/curriculum' element = {<Curriculum/>}/>
    <Route path='curriculum' element = {<Curriculum/>}/>
    <Route path='/Holistic-Learning' element = {<HolisticLearning/>}/>
    <Route path='/Admission-Process' element = {<AdmissionProcess/>}/>
    <Route path='/Pre Primary' element = {<PrePrimary/>}/>
    <Route path='/Primary' element = {<Primary/>}/>
    <Route path='/Secondary' element = {<Secondary/>}/>
    <Route path='/Information' element = {<Information/>}/>
    <Route path='/Facilities' element = {<Facilities/>}/>
    <Route path='/Achievements' element = {<Achievements/>}/>
    <Route path='/Building' element = {<Building/>}/>
    <Route path='/Playground' element = {<Playground/>}/>
    <Route path='/Classroom' element= {<Classroom/>}/>
    <Route path='/base' element={<Base/>}/>
    <Route path='/progress_promation' element= {<Progress_Promation/>}/>
    <Route path='/Login' element ={<Login/>}/>
    </Routes>  
      {/* <Banner/>
      <Header/>
      <Slider/>
      <Welcome/>
      <Schooldes/>
      <Message/>
      <Contactus/>
       <Gallery/>
      <Footer/> */}
    </div>
    
  );
}

export default App;
