import React from 'react';
import './Header1.css';
import { Link } from 'react-router-dom';

class OffcanvasExample extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false
    };
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleMenu() {
    this.setState({ showMenu: !this.state.showMenu });
  }

  render() {
    const { showMenu } = this.state;

    return (
      <nav className="navbar">
        <div className="navbar-container">
          
          <div className={`navbar-links ${showMenu ? 'active' : ''}`}>
            <Link className="link" to='/'>Home</Link>
            <div className="dropdown">
              <button className="dropbtn link">Academics</button>
              <div className="dropdown-content">
                <Link to="/curriculum">Curriculum</Link>
                <Link to="/Holistic-Learning">Holistic Learning</Link>
                <Link to="/Progress-Promation">Progress & Promation</Link>
              </div>
            </div>
            <div className="dropdown">
              <button className="dropbtn link">Admissions</button>
              <div className="dropdown-content">
                <Link to="/Admission-Process">Admission Process</Link>
                <Link to="/Pre Primary">Pre-Primary</Link>
                <Link to="/Primary">Primary</Link>
                <Link to="/Secondary">Secondary</Link>
              </div>
            </div>
            <Link className="link" to="/Achievements">Achievements</Link>
            <div className="dropdown">
              <button className="dropbtn link">Information</button>
              <div className="dropdown-content">
                <Link to="/Information">School Information</Link>
                <Link to="/Facilities">Facilities</Link>
              </div>
            </div>
            <div className="dropdown">
              <button className="dropbtn link">Infrastructure</button>
              <div className="dropdown-content">
                {/* <Link to="/Building">Building</Link> */}
                <Link to="/Playground">Playground</Link>
                <Link to="/Classroom">Classrooms</Link>
              </div>
            </div>
            <Link className="link" to="/Contact">Contact</Link>
            {/* <Link className="link" to="/Login">Login</Link> */}
          </div>
          
        </div>
        <div className="menu-button" onClick={this.toggleMenu}>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div>
      </nav>
    );
  }
}

export default OffcanvasExample;
