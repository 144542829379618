import React from "react";

// import component

import Banner1 from '../Components/Banner1';
import Header from '../Components/Header1';
import Footer from "../Components/Footer";
import Contactus from "../Components/Contactus";
import DownFooter from "../Components/DownFooter";
import Contect1 from "../Components/Contect1";
import './Contact.css';
import Base from "./Base";


const Contact= ()=>{
    return(
        <>
            <Banner1/>
            <Header/>
            <div className="contactPad" style={{display: "flex", paddingTop: 4+'rem'}}>
                <Contect1/>
                <Contactus/>
            </div>
            <Footer/>
            <DownFooter/>
        </>
    )
}
export default Contact